// import React from "react";
// import { Box, Grid, TextField, Typography } from "@mui/material";
// import { Company } from "../../../interfaces/Company";

// interface CompanyDetailsFormProps {
//   companyData: Partial<Company>;
//   setCompanyData: React.Dispatch<React.SetStateAction<Partial<Company>>>;
// }

// const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
//   companyData,
//   setCompanyData,
// }) => {
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setCompanyData({
//       ...companyData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   return (
//     <Box sx={{ mt: 3 }}>
//       <Typography variant="h6" gutterBottom>
//         Company Details
//       </Typography>
//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//           <TextField
//             required
//             id="name"
//             name="name"
//             label="Company Name"
//             fullWidth
//             variant="outlined"
//             value={companyData.name || ""}
//             onChange={handleChange}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           {/* Additional fields for contact person information */}
//         </Grid>
//         {/* Other form fields */}
//       </Grid>
//     </Box>
//   );
// };

// export default CompanyDetailsForm;

// import React, { ChangeEvent, useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Grid,
//   TextField,
//   Typography,
//   Select,
//   MenuItem,
//   FormControl,
//   InputLabel,
// } from "@mui/material";
// import { Company } from "../../../interfaces/Company";
// import { PhoneNumber } from "../../../interfaces/PhoneNumber";
// import UploadIcon from "@mui/icons-material/Upload";
// import { useGetCountriesQuery } from "../../../features/country/countrySlice";

// interface CompanyDetailsFormProps {
//   companyData: Partial<Company>;
//   setCompanyData: React.Dispatch<React.SetStateAction<Partial<Company>>>;
// }

// const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
//   companyData,
//   setCompanyData,
// }) => {
//   const [logoPreview, setLogoPreview] = useState<string | null>(null);
//   const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
//     country: "",
//     phoneNumber: 0,
//   });

//   const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setCompanyData({
//       ...companyData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setCompanyData((prevData) => ({
//           ...prevData,
//           logo: reader.result as string,
//         }));
//         setLogoPreview(reader.result as string);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   useEffect(() => {
//     if (countries && countries.length > 0) {
//       const defaultCountryId = countries[0]._id || "";
//       setPhoneNumber((prev) => ({
//         ...prev,
//         country: defaultCountryId as string,
//       }));
//     }
//   }, [countries]);

//   const handlePhoneNumberChange = (value: string) => {
//     const parsedPhoneNumber = parseInt(value);
//     setPhoneNumber((prev) => ({
//       ...prev,
//       phoneNumber: parsedPhoneNumber,
//     }));

//     setCompanyData((prevData) => ({
//       ...prevData,
//       contactPersonInformation: {
//         ...prevData.contactPersonInformation,
//         phoneNumber: {
//           ...prevData.contactPersonInformation?.phoneNumber,
//           phoneNumber: parsedPhoneNumber,
//           country:
//             prevData.contactPersonInformation?.phoneNumber?.country || "", // Ensure it's a string or a valid Country type
//         },
//         firstName: prevData.contactPersonInformation?.firstName || "", // Ensure it's a string
//         middleName: prevData.contactPersonInformation?.middleName || "", // Ensure it's a string
//         lastName: prevData.contactPersonInformation?.lastName || "", // Ensure it's a string
//         email: prevData.contactPersonInformation?.email || "", // Ensure it's a string
//       },
//     }));
//   };

//   const handleCountryChange = (value: string) => {
//     setPhoneNumber((prev) => ({
//       ...prev,
//       country: value,
//     }));

//     setCompanyData((prevData) => ({
//       ...prevData,
//       contactPersonInformation: {
//         ...prevData.contactPersonInformation,
//         phoneNumber: {
//           ...prevData.contactPersonInformation?.phoneNumber,
//           phoneNumber:
//             prevData.contactPersonInformation?.phoneNumber?.phoneNumber || 0,
//           country: value as string, // Ensure it's a string
//         },
//         firstName: prevData.contactPersonInformation?.firstName || "", // Ensure it's a string
//         middleName: prevData.contactPersonInformation?.middleName || "", // Ensure it's a string
//         lastName: prevData.contactPersonInformation?.lastName || "", // Ensure it's a string
//         email: prevData.contactPersonInformation?.email || "", // Ensure it's a string
//       },
//     }));
//   };

//   return (
//     <Box sx={{ mt: 3 }}>
//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//           <Typography variant="h6" gutterBottom>
//             Company Details
//           </Typography>
//           <TextField
//             required
//             id="name"
//             name="name"
//             label="Company Name"
//             fullWidth
//             variant="outlined"
//             value={companyData.name || ""}
//             onChange={handleChange}
//           />
//           <Box sx={{ margin: "16px 0" }}>
//             <Typography variant="h6" gutterBottom>
//               Logo Upload
//             </Typography>
//             <Box>
//               {logoPreview ? (
//                 <img
//                   src={logoPreview}
//                   alt="Logo Preview"
//                   style={{ width: "50%", maxHeight: "200px" }}
//                 />
//               ) : (
//                 <Typography>No Logo Uploaded</Typography>
//               )}
//             </Box>
//             <label htmlFor="logo">
//               <input
//                 type="file"
//                 id="logo"
//                 name="logo"
//                 accept="image/*"
//                 style={{ display: "none" }}
//                 onChange={handleImageUpload}
//               />
//               <Button
//                 variant="contained"
//                 component="span"
//                 startIcon={<UploadIcon />}
//                 fullWidth
//                 sx={{ marginTop: 2 }}
//               >
//                 Upload Logo
//               </Button>
//             </label>
//           </Box>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Typography variant="h6" gutterBottom>
//             Contact Person Information
//           </Typography>
//           <Grid container spacing={2}>
//             <Grid item xs={4}>
//               <FormControl fullWidth>
//                 <InputLabel>Country</InputLabel>
//                 <Select
//                   value={phoneNumber.country}
//                   onChange={(e) =>
//                     handleCountryChange(e.target.value as string)
//                   }
//                 >
//                   {countries &&
//                     countries.map((country) => (
//                       <MenuItem key={country._id} value={country._id}>
//                         {country.symbol} ({country.code})
//                       </MenuItem>
//                     ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={8}>
//               <TextField
//                 fullWidth
//                 type="number"
//                 label="Primary Phone"
//                 value={phoneNumber.phoneNumber || ""}
//                 onChange={(e) => handlePhoneNumberChange(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 id="firstName"
//                 name="firstName"
//                 label="First Name"
//                 fullWidth
//                 variant="outlined"
//                 value={companyData.contactPersonInformation?.firstName || ""}
//                 onChange={handleChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 id="middleName"
//                 name="middleName"
//                 label="Middle Name"
//                 fullWidth
//                 variant="outlined"
//                 value={companyData.contactPersonInformation?.middleName || ""}
//                 onChange={handleChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 id="lastName"
//                 name="lastName"
//                 label="Last Name"
//                 fullWidth
//                 variant="outlined"
//                 value={companyData.contactPersonInformation?.lastName || ""}
//                 onChange={handleChange}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 required
//                 id="email"
//                 name="email"
//                 label="Email"
//                 fullWidth
//                 variant="outlined"
//                 value={companyData.contactPersonInformation?.email || ""}
//                 onChange={handleChange}
//               />
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default CompanyDetailsForm;

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Company } from "../../../interfaces/Company";
import { PhoneNumber } from "../../../interfaces/PhoneNumber";
import UploadIcon from "@mui/icons-material/Upload";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";
import { Country } from "../../../interfaces/Country";

interface CompanyDetailsFormProps {
  companyData: Partial<Company>;
  setCompanyData: React.Dispatch<React.SetStateAction<Partial<Company>>>;
  phoneError: string;
  setPhoneError: React.Dispatch<React.SetStateAction<string>>;
}

const CompanyDetailsForm: React.FC<CompanyDetailsFormProps> = ({
  companyData,
  setCompanyData,
  phoneError,
  setPhoneError,
}) => {
  const [logoPreview, setLogoPreview] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });

  const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setCompanyData((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  useEffect(() => {
    refetchCountries();
  }, []);

  useEffect(() => {
    if (companyData?.contactPersonInformation) {
      setPhoneNumber({
        country:
          companyData?.contactPersonInformation.phoneNumber?.country || "",
        phoneNumber:
          companyData?.contactPersonInformation.phoneNumber?.phoneNumber || 0,
      });
    }

    if (companyData?.logo) {
      const logoPreview =
        typeof companyData?.logo === "string"
          ? companyData?.logo
          : companyData?.logo?.url; // or another property based on your ImageType structure

      setLogoPreview(logoPreview);
    }
  }, [companyData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCompanyData((prev) => {
      const contactInfo = prev?.contactPersonInformation || {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber: {
          country: "",
          phoneNumber: 0,
        },
      };

      if (name.startsWith("contactPersonInformation.")) {
        const fieldName = name.split("contactPersonInformation.")[1];
        return {
          ...prev,
          contactPersonInformation: {
            ...contactInfo,
            [fieldName]: value,
          },
        };
      }

      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyData((prevData) => ({
          ...prevData,
          logo: reader.result as string,
        }));
        setLogoPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (countries && countries.length > 0 && !phoneNumber.country) {
      const defaultCountryId = countries[0]._id || "";
      setPhoneNumber((prev) => ({
        ...prev,
        country: defaultCountryId as string,
      }));

      setCompanyData((prevData) => ({
        ...prevData,
        contactPersonInformation: {
          ...(prevData?.contactPersonInformation || {}),
          phoneNumber: {
            ...prevData?.contactPersonInformation?.phoneNumber,
            phoneNumber:
              prevData?.contactPersonInformation?.phoneNumber?.phoneNumber || 0,
            country: defaultCountryId as string, // Ensure it's a string
          },
          firstName: prevData?.contactPersonInformation?.firstName || "", // Ensure it's a string
          middleName: prevData?.contactPersonInformation?.middleName || "", // Ensure it's a string
          lastName: prevData?.contactPersonInformation?.lastName || "", // Ensure it's a string
          email: prevData?.contactPersonInformation?.email || "", // Ensure it's a string
        },
      }));
    }
  }, [countries, phoneNumber.country]);

  useEffect(() => {
    const selectedCountry = countries?.find(
      (country: Country) => country._id === phoneNumber.country
    );

    // If a country is selected, validate phone number length
    if (selectedCountry) {
      if (
        phoneNumber.phoneNumber.toString().length !== selectedCountry.length
      ) {
        setPhoneError(
          `Phone number must be exactly ${selectedCountry.length} digits`
        );
      } else {
        setPhoneError(""); // Clear the error if length is valid
      }
    }
  }, [phoneNumber.phoneNumber, phoneNumber.country]);

  const handlePhoneNumberChange = (value: string) => {
    // const selectedCountry = countries?.find(
    //   (country: Country) => country._id === phoneNumber.country
    // );

    // // If a country is selected, validate phone number length
    // if (selectedCountry) {
    //   if (value.length !== selectedCountry.length) {
    //     setPhoneError(
    //       `Phone number must be exactly ${selectedCountry.length} digits`
    //     );
    //   } else {
    //     setPhoneError(""); // Clear the error if length is valid
    //   }
    // }
    const parsedPhoneNumber = parseInt(value);
    setPhoneNumber((prev) => ({
      ...prev,
      phoneNumber: parsedPhoneNumber,
    }));

    setCompanyData((prevData) => ({
      ...prevData,
      contactPersonInformation: {
        ...prevData.contactPersonInformation,
        phoneNumber: {
          ...prevData.contactPersonInformation?.phoneNumber,
          phoneNumber: parsedPhoneNumber,
          country:
            prevData.contactPersonInformation?.phoneNumber?.country || "", // Ensure it's a string or a valid Country type
        },
        firstName: prevData.contactPersonInformation?.firstName || "", // Ensure it's a string
        middleName: prevData.contactPersonInformation?.middleName || "", // Ensure it's a string
        lastName: prevData.contactPersonInformation?.lastName || "", // Ensure it's a string
        email: prevData.contactPersonInformation?.email || "", // Ensure it's a string
      },
    }));
  };

  const handleCountryChange = (value: string) => {
    setPhoneNumber((prev) => ({
      ...prev,
      country: value,
    }));
    setPhoneError(""); // Reset error when changing country
    setCompanyData((prevData) => ({
      ...prevData,
      contactPersonInformation: {
        ...prevData.contactPersonInformation,
        phoneNumber: {
          ...prevData.contactPersonInformation?.phoneNumber,
          phoneNumber:
            prevData.contactPersonInformation?.phoneNumber?.phoneNumber || 0,
          country: value as string, // Ensure it's a string
        },
        firstName: prevData.contactPersonInformation?.firstName || "", // Ensure it's a string
        middleName: prevData.contactPersonInformation?.middleName || "", // Ensure it's a string
        lastName: prevData.contactPersonInformation?.lastName || "", // Ensure it's a string
        email: prevData.contactPersonInformation?.email || "", // Ensure it's a string
      },
    }));
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Company Details
          </Typography>
          <TextField
            required
            id="name"
            name="name"
            label="Company Name"
            fullWidth
            variant="outlined"
            value={companyData?.name || ""}
            onChange={handleChange}
          />
          <Box sx={{ margin: "16px 0" }}>
            <Typography variant="h6" gutterBottom>
              Logo Upload
            </Typography>
            <Box>
              {logoPreview ? (
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  style={{ width: "50%", maxHeight: "200px" }}
                />
              ) : (
                <Typography>No Logo Uploaded</Typography>
              )}
            </Box>
            <label htmlFor="logo">
              <input
                type="file"
                id="logo"
                name="logo"
                accept="image/png, image/jpeg, image/jpg"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <Button
                variant="contained"
                component="span"
                startIcon={<UploadIcon />}
                fullWidth
                sx={{ marginTop: 2 }}
              >
                Upload Logo
              </Button>
            </label>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            Contact Person Information
          </Typography>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                required
                id="firstName"
                name="contactPersonInformation.firstName"
                label="First Name"
                fullWidth
                variant="outlined"
                value={companyData?.contactPersonInformation?.firstName || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="middleName"
                name="contactPersonInformation.middleName"
                label="Middle Name"
                fullWidth
                variant="outlined"
                value={companyData?.contactPersonInformation?.middleName || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="lastName"
                name="contactPersonInformation.lastName"
                label="Last Name"
                fullWidth
                variant="outlined"
                value={companyData?.contactPersonInformation?.lastName || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="contactPersonInformation.email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={companyData?.contactPersonInformation?.email || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  value={phoneNumber?.country}
                  onChange={(e) =>
                    handleCountryChange(e.target.value as string)
                  }
                  label="Country"
                >
                  {countries &&
                    countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.symbol} ({country.code})
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                variant="outlined"
                value={phoneNumber?.phoneNumber || 0}
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
                error={!!phoneError}
                helperText={phoneError}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyDetailsForm;
