import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { UserRole } from "../../../interfaces/UserRole";
import {
  useCreateUserMutation,
  useGetSubordinatesQuery,
} from "../../../features/user/userSlice";
import {
  selectCurrentCompany,
  selectCurrentUser,
} from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { ChromePicker } from "react-color"; // Color picker import

const AddUser: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [color, setColor] = useState<string>("#000000"); // Add color state
  const [role, setRole] = useState<UserRole | UserRole.SALES>(UserRole.SALES);
  const [parentUser, setParentUser] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [createUser] = useCreateUserMutation();

  const company = useSelector((state: RootState) =>
    selectCurrentCompany(state)
  );

  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  console.log("userData", userData);
  console.log("company", company);

  const { data: subordinates, refetch: subRefetch } = useGetSubordinatesQuery();

  useEffect(() => {
    subRefetch();
  }, [subRefetch]);

  const roleOptions = Object.values(UserRole).filter(
    (role) => role !== UserRole.OWNER
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await createUser({
        name,
        email,
        password,
        company: company._id,
        role,
        parentUser: parentUser || "",
        color, // Add the color when submitting
      }).unwrap();
      setName("");
      setEmail("");
      setPassword("");
      setRole(UserRole.SALES);
      setParentUser("");
      setColor("#000000");
      navigate("/dashboard/user");
      toast.success("User added successfully");
    } catch (err) {
      if (err && typeof err === "object" && "status" in err && "data" in err) {
        const errorMessage = (err as any).data?.message || "Failed to add user";
        // console.error(err);
        // toast.error(errorMessage);
        if (err.status === 400) {
          toast.error(errorMessage);
        } else {
          toast.error("Failed to add user");
        }
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Add New User
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="User Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Password"
          variant="outlined"
          margin="normal"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Role</InputLabel>
          <Select
            fullWidth
            variant="outlined"
            value={role}
            onChange={(e) => setRole(e.target.value as UserRole)}
            displayEmpty
            required
          >
            <MenuItem value="">
              <em>Select Role</em>
            </MenuItem>
            {roleOptions.map((roleOption) => (
              <MenuItem key={roleOption} value={roleOption}>
                {roleOption.charAt(0).toUpperCase() +
                  roleOption.slice(1).replace(/_/g, " ")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Parent User</InputLabel>
          <Select
            fullWidth
            variant="outlined"
            value={parentUser}
            onChange={(e) => setParentUser(e.target.value)}
            displayEmpty
            // required
          >
            <MenuItem value="">
              <em>Select Parent User</em>
            </MenuItem>
            {subordinates?.map((subordinate) => (
              <MenuItem key={subordinate._id} value={subordinate._id}>
                {subordinate.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Color Picker */}
        <Typography variant="body1" sx={{ mt: 2 }}>
          Select User Color:
        </Typography>
        <ChromePicker color={color} onChange={(color) => setColor(color.hex)} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? <CircularProgress size={24} /> : "Add User"}
        </Button>
      </form>
    </Box>
  );
};

export default AddUser;
