import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setCredentials } from "../../features/auth/authSlice";
import { SubscriptionStatus } from "../../interfaces/Subscription";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        CRM Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

type ErrorResponse = {
  data?: {
    statusCode?: number;
    message?: string | string[];
  };
};

export default function Login() {
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const generateError = (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // Perform the login mutation
    const result = await login({
      email: data.get("email") as string,
      password: data.get("password") as string,
    });

    // Check if the result contains an error
    if ("error" in result) {
      const error = result.error as ErrorResponse;
      if (error.data?.statusCode === 401) {
        generateError("Invalid email or password");
      } else if (error.data?.statusCode === 400) {
        if (Array.isArray(error.data.message)) {
          error.data.message.forEach((msg: string) => generateError(msg));
        } else {
          generateError("Invalid error format");
        }
      } else {
        generateError("Login Failed");
      }
    }

    // Check if the result contains data (successful login)
    if ("data" in result) {
      const userData = result.data;

      dispatch(setCredentials({ ...userData }));

      if (userData.user.role === "owner") {
        const companyId = userData.user.company?._id;
        const subscriptionId = userData.user.company?.subscription?._id;
        const status = userData.user.company?.subscription?.status;

        if (
          !companyId ||
          !subscriptionId ||
          status === SubscriptionStatus.READY_TO_PAY
        ) {
          navigate("/company-setup");
        } else {
          navigate("/dashboard");
        }
      } else {
        navigate("/dashboard");
      }
    }
  };

  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);

  //   try {
  //     const result = await login({
  //       email: data.get("email") as string,
  //       password: data.get("password") as string,
  //     });

  //     if ("data" in result) {
  //       const userData = result.data;

  //       console.log("Login Response:", userData);

  //       dispatch(setCredentials({ ...userData }));

  //       // if (userData.user.role === "owner") {
  //       //   console.log("Redux State after login:", store.getState());
  //       //   console.log("Navigating to /company-setup"); // Add this log
  //       //   console.log("Expected Role:", UserRole.OWNER);
  //       //   console.log("Actual Role from userData:", userData.user.role);

  //       //   navigate("/company-setup");
  //       // } else {
  //       //   navigate("/dashboard");
  //       // }

  //       if (userData.user.role === "owner") {
  //         const companyId = userData.user.company?._id;
  //         const subscriptionId = userData.user.company?.subscription?._id;
  //         const status = userData.user.company?.subscription?.status;

  //         console.log(companyId, subscriptionId, status);

  //         if (!companyId) {
  //           navigate("/company-setup");
  //         } else if (!subscriptionId) {
  //           navigate("/company-setup");
  //         } else if (status === SubscriptionStatus.READY_TO_PAY) {
  //           navigate("/company-setup");
  //         } else {
  //           navigate("/dashboard");
  //         }
  //       } else {
  //         navigate("/dashboard");
  //       }
  //     }
  //   } catch (err) {
  //     const error = err as ErrorResponse;
  //     if (!error?.data?.statusCode) {
  //       generateError("No Server Response");
  //     } else if (error.data?.statusCode === 400) {
  //       if (Array.isArray(error.data.message)) {
  //         error.data.message.forEach((msg: string) => {
  //           generateError(msg);
  //         });
  //       } else {
  //         generateError("Invalid error format");
  //       }
  //     } else if (error.data?.statusCode === 401) {
  //       generateError("Unauthorized");
  //     } else {
  //       generateError("Login Failed");
  //     }
  //   }
  // };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <ToastContainer />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"} // Toggle between text and password
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? "Logging..." : "Sign In"}{" "}
              {/* Show "Logging..." while loading */}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
