// import { combineReducers } from 'redux';
// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import { campaignApiSlice } from './campaign/campaignSlice';
// import { countryApiSlice } from './country/countrySlice';
// import { leadTypeApiSlice } from './lead_type/leadTypeSlice';
// import { leadSubTypeApiSlice } from './lead_sub_type/leadSubTypeSlice';
// import { advertisementApiSlice } from './advertisement/advertisementSlice';
// import { adGroupApiSlice } from './ad_group/adGroupSlice';
// import { contactMethodApiSlice } from './contact_method/contactMethodSlice';
// import { locationApiSlice } from './location/locationSlice';
// import { leadApiSlice } from './lead/leadSlice';
// import { authApiSlice } from './auth/authApiSlice';
// import authReducer from './auth/authSlice';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const rootReducer = combineReducers({
//     auth: authReducer,
//     authApi: authApiSlice.reducer,
//     leadApi: leadApiSlice.reducer,
//     campaignApi: campaignApiSlice.reducer,
//     countryApi: countryApiSlice.reducer,
//     leadTypeApi: leadTypeApiSlice.reducer,
//     leadSubTypeApi: leadSubTypeApiSlice.reducer,
//     advertisementApi: advertisementApiSlice.reducer,
//     adGroupApi: adGroupApiSlice.reducer,
//     contactMethodApi: contactMethodApiSlice.reducer,
//     locationApi: locationApiSlice.reducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }).concat(
//         authApiSlice.middleware,
//         leadTypeApiSlice.middleware,
//         leadSubTypeApiSlice.middleware,
//         advertisementApiSlice.middleware,
//         adGroupApiSlice.middleware,
//         contactMethodApiSlice.middleware,
//         locationApiSlice.middleware,
//         campaignApiSlice.middleware,
//         countryApiSlice.middleware,
//         leadApiSlice.middleware
//     ),
// });

// export const persistor = persistStore(store);

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { campaignApiSlice } from "./campaign/campaignSlice";
import { countryApiSlice } from "./country/countrySlice";
import { leadTypeApiSlice } from "./lead_type/leadTypeSlice";
import { leadSubTypeApiSlice } from "./lead_sub_type/leadSubTypeSlice";
import { leadStatusApiSlice } from "./lead_status/leadStatusSlice";
import { advertisementApiSlice } from "./advertisement/advertisementSlice";
import { adGroupApiSlice } from "./ad_group/adGroupSlice";
import { contactMethodApiSlice } from "./contact_method/contactMethodSlice";
import { locationApiSlice } from "./location/locationSlice";
import { leadApiSlice } from "./lead/leadSlice";
import { authApiSlice } from "./auth/authApiSlice";
import authReducer from "./auth/authSlice";
import { adminApiSlice } from "./admin/adminApiSlice";
import adminReducer from "./admin/adminSlice";
import { companyApiSlice } from "./company/companySlice";
import { pricingPlanApiSlice } from "./pricing_plan/pricingPlanSlice";
import { userPricingPlanApiSlice } from "./pricing_plan/userPricingPlanSlice";
import { durationApiSlice } from "./duration/durationSlice";
import { paymentApiSlice } from "./payment/paymentSlice";
import { subscriptionApiSlice } from "./subscription/subscriptionSlice";
import { userApiSlice } from "./user/userSlice";
import { adminUserApiSlice } from "./user/adminUserSlice";
import { adminCompanyApiSlice } from "./company/adminCompanySlice";
import { adminLeadStatusApiSlice } from "./lead_status/adminLeadStatusSlice";
import { adminSubscriptionApiSlice } from "./subscription/adminSubscriptionSlice";
import { adminPaymentApiSlice } from "./payment/adminPaymentSlice";
import { userDurationApiSlice } from "./duration/userDurationSlice";
import { leadTransactionApiSlice } from "./lead_transaction/leadTransactionSlice";
import redirectSlice from "./redirectSlice";

// Redux Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Root reducer combining all slices
const rootReducer = combineReducers({
  redirect: redirectSlice, // Add redirect slice here
  auth: authReducer,
  authApi: authApiSlice.reducer,
  admin: adminReducer,
  adminApi: adminApiSlice.reducer,
  leadApi: leadApiSlice.reducer,
  campaignApi: campaignApiSlice.reducer,
  countryApi: countryApiSlice.reducer,
  leadTypeApi: leadTypeApiSlice.reducer,
  leadSubTypeApi: leadSubTypeApiSlice.reducer,
  leadStatusApi: leadStatusApiSlice.reducer,
  leadTransactionApi: leadTransactionApiSlice.reducer,
  advertisementApi: advertisementApiSlice.reducer,
  adGroupApi: adGroupApiSlice.reducer,
  contactMethodApi: contactMethodApiSlice.reducer,
  locationApi: locationApiSlice.reducer,
  companyApi: companyApiSlice.reducer,
  pricingPlanApi: pricingPlanApiSlice.reducer,
  userPricingPlanApi: userPricingPlanApiSlice.reducer,
  durationApi: durationApiSlice.reducer,
  paymentApi: paymentApiSlice.reducer,
  subscriptionApi: subscriptionApiSlice.reducer,
  userDurationApi: userDurationApiSlice.reducer,
  userApi: userApiSlice.reducer,
  adminUserApi: adminUserApiSlice.reducer,
  adminCompanyApi: adminCompanyApiSlice.reducer,
  adminLeadStatusApi: adminLeadStatusApiSlice.reducer,
  adminSubscriptionApi: adminSubscriptionApiSlice.reducer,
  adminPaymentApi: adminPaymentApiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApiSlice.middleware,
      adminApiSlice.middleware,
      leadTypeApiSlice.middleware,
      leadSubTypeApiSlice.middleware,
      leadStatusApiSlice.middleware,
      leadTransactionApiSlice.middleware,
      advertisementApiSlice.middleware,
      adGroupApiSlice.middleware,
      contactMethodApiSlice.middleware,
      locationApiSlice.middleware,
      campaignApiSlice.middleware,
      countryApiSlice.middleware,
      leadApiSlice.middleware,
      companyApiSlice.middleware,
      pricingPlanApiSlice.middleware,
      userPricingPlanApiSlice.middleware,
      durationApiSlice.middleware,
      paymentApiSlice.middleware,
      subscriptionApiSlice.middleware,
      userDurationApiSlice.middleware,
      userApiSlice.middleware,
      adminUserApiSlice.middleware,
      adminCompanyApiSlice.middleware,
      adminLeadStatusApiSlice.middleware,
      adminSubscriptionApiSlice.middleware,
      adminPaymentApiSlice.middleware
    ),
});

// Persistor
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
