// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { refreshToken } from './refreshToken';

// const authBaseQuery = fetchBaseQuery({
//     baseUrl: 'http://localhost:3001',
//     credentials: 'include',
//     prepareHeaders: (headers, { getState }) => {
//         const token = getState().auth.token;
//         if (token) {
//             headers.set('Authorization', `Bearer ${token}`);
//         }
//         return headers;
//     },
// });

// const authBaseQueryWithReauth = async (args, api, extraOptions) => {
//     return refreshToken(args, api, extraOptions, authBaseQuery);
// };

// export const authApiSlice = createApi({
//     reducerPath: 'authApi',
//     baseQuery: authBaseQueryWithReauth,
//     endpoints: (builder) => ({
//         login: builder.mutation({
//             query: (credentials) => ({
//                 url: '/auth/login',
//                 method: 'POST',
//                 body: {...credentials},
//             }),
//         }),
//         register: builder.mutation({
//             query: (user) => ({
//                 url: '/auth/register',
//                 method: 'POST',
//                 body: user,
//             }),
//         }),
//         updateAvatar: builder.mutation({
//             query: ({ id, avatar }) => ({
//                 url: `/auth/avatar/${id}`,
//                 method: 'PUT',
//                 body: { avatar },
//             }),
//         }),
//         updateUser: builder.mutation({
//             query: ({ id, updateUser }) => ({
//                 url: `/auth/${id}`,
//                 method: 'PUT',
//                 body: updateUser,
//             }),
//         }),
//         getUser: builder.query({
//             query: (id) => `/auth/${id}`,
//         }),
//     }),
// });

// export const {
//     useLoginMutation,
//     useRegisterMutation,
//     useUpdateAvatarMutation,
//     useUpdateUserMutation,
//     useGetUserQuery,
// } = authApiSlice;

import { createApi } from "@reduxjs/toolkit/query/react";
import { User } from "../../interfaces/User";
import { authBaseQueryWithReauth } from "../auth/authBaseQuery";

interface Credentials {
  email: string;
  password: string;
}

interface UpdateUserPayload {
  id: string;
  updateUser: Partial<User>;
}

interface UpdateAvatarPayload {
  id: string;
  avatar: string;
}

// const authBaseQuery = fetchBaseQuery({
//   baseUrl: "http://localhost:3001",
//   credentials: "include",
//   prepareHeaders: (headers, { getState }) => {
//     const token = (getState() as RootState).auth.token;
//     if (token) {
//       headers.set("Authorization", `Bearer ${token}`);
//     }
//     return headers;
//   },
// });

// const authBaseQueryWithReauth = async (
//   args: any,
//   api: any,
//   extraOptions: any
// ) => {
//   return refreshToken(args, api, extraOptions, authBaseQuery);
// };

export const authApiSlice = createApi({
  reducerPath: "authApi",
  baseQuery: authBaseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation<any, Credentials>({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation<any, Partial<User>>({
      query: (user) => ({
        url: "/auth/register",
        method: "POST",
        body: user,
      }),
    }),
    updateAvatar: builder.mutation<any, UpdateAvatarPayload>({
      query: ({ id, avatar }) => ({
        url: `/auth/avatar/${id}`,
        method: "PUT",
        body: { avatar },
      }),
    }),
    updateUser: builder.mutation<any, UpdateUserPayload>({
      query: ({ id, updateUser }) => ({
        url: `/auth/${id}`,
        method: "PUT",
        body: updateUser,
      }),
    }),
    getUser: builder.query<any, string>({
      query: (id) => `/auth/${id}`,
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useUpdateAvatarMutation,
  useUpdateUserMutation,
  useGetUserQuery,
} = authApiSlice;
