import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLoginMutation } from "../../features/admin/adminApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setCredentials } from "../../features/admin/adminSlice";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.orcida.com/">
        Orcida
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

type ErrorResponse = {
  data?: {
    statusCode?: number;
    message?: string | string[];
  };
};

export default function AdminLogin() {
  const navigate = useNavigate();

  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  const generateError = (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const result = await login({
        email: data.get("email") as string,
        password: data.get("password") as string,
      });

      if ("data" in result) {
        const adminData = result.data;

        console.log("Login Response:", adminData);

        dispatch(setCredentials({ ...adminData }));

        // if (adminData.admin.role === "owner") {
        //   console.log("Redux State after login:", store.getState());
        //   console.log("Navigating to /company-setup"); // Add this log
        //   console.log("Expected Role:", UserRole.OWNER);
        //   console.log("Actual Role from adminData:", adminData.admin.role);

        //   navigate("/company-setup");
        // } else {
        //   navigate("/dashboard");
        // }

        if (adminData.admin.role === "admin") {
          navigate("/admin-dashboard");
        }
      }
    } catch (err) {
      const error = err as ErrorResponse;
      if (!error?.data?.statusCode) {
        generateError("No Server Response");
      } else if (error.data?.statusCode === 400) {
        if (Array.isArray(error.data.message)) {
          error.data.message.forEach((msg: string) => {
            generateError(msg);
          });
        } else {
          generateError("Invalid error format");
        }
      } else if (error.data?.statusCode === 401) {
        generateError("Unauthorized");
      } else {
        generateError("Login Failed");
      }
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <ToastContainer />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/admin-register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
