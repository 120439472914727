// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "./authSlice";

// const RedirectIfAuthenticated = () => {
//   const token = useSelector(selectCurrentToken);
//   const location = useLocation();

//   return token ? (
//     <Navigate to="/dashboard" state={{ from: location }} replace />
//   ) : (
//     <Outlet />
//   );
// };

// export default RedirectIfAuthenticated;

import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "./authSlice";
import { RootState } from "../store";
import { SubscriptionStatus } from "../../interfaces/Subscription";
import { PaymentStatus } from "../../interfaces/Payment";

const RedirectIfAuthenticated: React.FC = () => {
  const token = useSelector((state: RootState) => selectCurrentToken(state));
  // const user = useSelector((state: RootState) => selectCurrentUser(state));
  const user = useSelector((state: RootState) => selectCurrentUser(state));
  console.log("user", user);

  // const { data: user, refetch: refetchUser } = useGetUserQuery(userData?._id!);
  const location = useLocation();

  if (token && user) {
    // If the user is authenticated and needs to complete company setup
    if (
      user?.role === "owner" &&
      (user?.company === null ||
        user.company?.subscription === null ||
        user.company?.subscription?.payment === null)
    ) {
      return (
        <Navigate to="/company-setup" state={{ from: location }} replace />
      );
    } else if (
      user?.role === "owner" &&
      (user?.company?.subscription?.payment?.status === PaymentStatus.PENDING ||
        user?.company?.subscription?.payment?.status ===
          PaymentStatus.REQUESTED)
    ) {
      return (
        <Navigate
          to="/waiting-confirmation"
          state={{ from: location }}
          replace
        />
      );
    } else if (
      user?.role === "owner" &&
      user?.company?.subscription?.status === SubscriptionStatus.EXPIRED
    ) {
      return (
        <Navigate
          to="/subscription-expired"
          state={{ from: location }}
          replace
        />
      );
    }
    // Otherwise, redirect to the dashboard
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  // If not authenticated, render the child components (e.g., login, register)
  return <Outlet />;
};

export default RedirectIfAuthenticated;
