import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { selectCurrentUser } from "../../../features/auth/authSlice";
import { PaymentStatus } from "../../../interfaces/Payment";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../../../features/user/userSlice";

const WaitingConfirmation = () => {
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => selectCurrentUser(state));
  const {
    data: user,
    refetch: refetchUser,
    isSuccess,
  } = useGetUserQuery(userData._id!, {
    refetchOnMountOrArgChange: true,
  });

  console.log("user", user);

  useEffect(() => {
    if (userData._id) {
      console.log("refetching user");
      refetchUser();
    }
  }, [userData._id, refetchUser]);

  useEffect(() => {
    if (
      isSuccess &&
      typeof user.company === "object" &&
      user.company !== null &&
      typeof user.company.subscription === "object" &&
      user.company.subscription !== null &&
      typeof user.company.subscription.payment === "object" &&
      user.company.subscription.payment !== null &&
      user.company?.subscription?.payment.status === PaymentStatus.SUCCESSFUL
    ) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Card
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <CardContent>
          <CircularProgress sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom>
            Waiting for Confirmation
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Thank you for your patience. Your account will be activated soon
            after approval.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WaitingConfirmation;
