import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { UserRole } from "../../../interfaces/UserRole";
import {
  useGetSubordinatesQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../../features/user/userSlice";
import { ChromePicker } from "react-color";

const EditUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<UserRole | UserRole.SALES>(UserRole.SALES);
  const [parentUser, setParentUser] = useState<string>("");
  const [color, setColor] = useState<string>("#000000"); // Add color state
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const roleOptions = Object.values(UserRole).filter(
    (role) => role !== UserRole.OWNER
  );

  const { data: userData, isLoading, isError, refetch } = useGetUserQuery(id!);
  const { data: subordinates, refetch: subRefetch } = useGetSubordinatesQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    subRefetch();
  }, [subRefetch]);

  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (userData) {
      setName(userData.name || "");
      setEmail(userData.email || "");
      setColor(userData.color || "#000000");
      setRole(userData.role || UserRole.SALES);
      setParentUser(userData.parentUser?.toString() || "");
    }
  }, [userData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateUser({
        id: id!,
        user: { name, email, color, role, parentUser: parentUser || "" },
      }).unwrap();
      toast.success("User updated successfully");
      navigate("/dashboard/user");
    } catch (err) {
      toast.error("Failed to update user");
    } finally {
      setIsFormLoading(false);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (isError)
    return <Typography color="error">Error loading user data</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Edit User
      </Typography>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="User Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Role</InputLabel>
          <Select
            fullWidth
            variant="outlined"
            value={role}
            onChange={(e) => setRole(e.target.value as UserRole)}
            displayEmpty
            required
          >
            <MenuItem value="">
              <em>Select Role</em>
            </MenuItem>
            {roleOptions.map((roleOption) => (
              <MenuItem key={roleOption} value={roleOption}>
                {roleOption.charAt(0).toUpperCase() +
                  roleOption.slice(1).replace(/_/g, " ")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="lead-type-select-label">Parent User</InputLabel>
          <Select
            fullWidth
            variant="outlined"
            value={parentUser}
            onChange={(e) => setParentUser(e.target.value)}
            displayEmpty
            required
          >
            <MenuItem value="">
              <em>Select Parent User</em>
            </MenuItem>
            {subordinates?.map((subordinate) => (
              <MenuItem key={subordinate._id} value={subordinate._id}>
                {subordinate.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Edit User Color:
        </Typography>
        <ChromePicker color={color} onChange={(color) => setColor(color.hex)} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isFormLoading}
          sx={{ mt: 2 }}
        >
          {isFormLoading ? <CircularProgress size={24} /> : "Update User"}
        </Button>
      </form>
    </Box>
  );
};

export default EditUser;
