import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Avatar,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import { Logout, Settings, HelpOutline } from "@mui/icons-material";
import {
  logOut,
  selectCurrentAdmin,
  selectCurrentAdminRole,
} from "../features/admin/adminSlice";
import profileImg from "./images/user.jpg";

// Define a mapping from role keys to display strings
const roleDisplayMap = {
  admin: "ADMIN",
};

type Role = "admin";

// Utility function to get the display name for a role
const getRoleDisplayName = (role: Role): string => {
  return roleDisplayMap[role] || "Role";
};

const NavAvatar = () => {
  const dispatch = useDispatch();
  const admin = useSelector(selectCurrentAdmin);
  const role = useSelector(selectCurrentAdminRole);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    dispatch(logOut());
    window.location.href = "/admin-login";
  };

  console.log("admin", admin);

  return (
    <div>
      <IconButton onClick={handleMenuOpen} color="inherit">
        <Avatar alt="Admin" src={admin?.avatar?.url || profileImg} />
        {/* <Typography variant="body2" sx={{ marginLeft: 1 }}>
          {admin?.name || "Guest"}
        </Typography> */}
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem disabled>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle1">
              {admin?.name || "Guest"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getRoleDisplayName(role) || "Role"}
            </Typography>
          </Box>
        </MenuItem>

        <Divider />

        <MenuItem
          component={Link}
          to={`/admin-dashboard/profile/${admin._id}`}
          onClick={handleMenuClose}
        >
          <Avatar
            alt="Profile"
            src={admin?.avatar?.url || profileImg}
            sx={{ marginRight: 2 }}
          />
          My Profile
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleMenuClose}>
          <Settings fontSize="small" sx={{ marginRight: 2 }} />
          Account Settings
        </MenuItem>

        <MenuItem onClick={handleMenuClose}>
          <HelpOutline fontSize="small" sx={{ marginRight: 2 }} />
          Need Help?
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleSignOut}>
          <Logout fontSize="small" sx={{ marginRight: 2 }} />
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default NavAvatar;
