// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate, NavLink } from 'react-router-dom';
// import { useUpdateLeadMutation, useGetLeadQuery } from '../../../../features/lead/leadSlice';
// import { toast, ToastContainer } from 'react-toastify';
// import { useGetLeadTypesQuery } from '../../../../features/lead_type/leadTypeSlice';
// import { useGetLeadSubTypesQuery } from '../../../../features/lead_sub_type/leadSubTypeSlice';
// import { useGetCampaignsQuery } from '../../../../features/campaign/campaignSlice';
// import { useGetAdvertisementsQuery } from '../../../../features/advertisement/advertisementSlice';
// import { useGetAdGroupsQuery } from '../../../../features/ad_group/adGroupSlice';
// import { useGetContactMethodsQuery } from '../../../../features/contact_method/contactMethodSlice';
// import { useGetLocationsQuery } from '../../../../features/location/locationSlice';
// import { useGetCountriesQuery } from '../../../../features/country/countrySlice';

// const EditLead = () => {
//     const { id } = useParams();
//     const navigate = useNavigate();
//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');
//     const [leadType, setLeadType] = useState('');
//     const [leadSubType, setLeadSubType] = useState('');
//     const [primaryPhone, setPrimaryPhone] = useState({ country: '', phoneNumber: '' });
//     const [secondaryPhone, setSecondaryPhone] = useState({ country: '', phoneNumber: '' });
//     const [landline, setLandline] = useState('');
//     const [campaign, setCampaign] = useState('');
//     const [advertisement, setAdvertisement] = useState('');
//     const [adGroup, setAdGroup] = useState('');
//     const [contactMethod, setContactMethod] = useState('');
//     const [preferredCallTime, setPreferredCallTime] = useState('');
//     const [interestedLocation, setInterestedLocation] = useState('');
//     const [budgetDetails, setBudgetDetails] = useState({});
//     const [budgetValue, setBudgetValue] = useState('');
//     const [budgetCurrency, setBudgetCurrency] = useState('USD');
//     const [assignedSalesAgent, setAssignedSalesAgent] = useState('');
//     const [trackingID, setTrackingID] = useState('');
//     const [notes, setNotes] = useState('');
//     const [isFormLoading, setIsFormLoading] = useState(false);

//     const { data: leadTypes, refetch: refetchLeadTypes } = useGetLeadTypesQuery();
//     const { data: allLeadSubTypes, refetch: refetchLeadSubTypes } = useGetLeadSubTypesQuery();
//     const { data: campaigns, refetch: refetchCampaigns } = useGetCampaignsQuery();
//     const { data: allAdvertisements, refetch: refetchAdvertisements } = useGetAdvertisementsQuery();
//     const { data: adGroups, refetch: refetchAdGroups } = useGetAdGroupsQuery();
//     const { data: contactMethods, refetch: refetchContactMethods } = useGetContactMethodsQuery();
//     const { data: locations, refetch: refetchLocations } = useGetLocationsQuery();
//     const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

//     const { data: leadData, refetch } = useGetLeadQuery(id);
//     const [updateLead] = useUpdateLeadMutation();

//     useEffect(() => {
//         refetch();
//     }, [refetch]);

//     const [filteredLeadSubTypes, setFilteredLeadSubTypes] = useState([]);
//     const [filteredAdvertisements, setFilteredAdvertisements] = useState([]);

//     // Filter lead subtypes based on the selected lead type
//     useEffect(() => {
//         if (leadType && allLeadSubTypes) {
//         const filtered = allLeadSubTypes.filter(subType => subType.leadType === leadType);
//         setFilteredLeadSubTypes(filtered);
//         } else {
//         setFilteredLeadSubTypes([]);
//         }
//     }, [leadType, allLeadSubTypes]);

//     useEffect(() => {
//         if (adGroup && allAdvertisements) {
//         const filtered = allAdvertisements.filter(advertisement => advertisement.adGroup === adGroup);
//         setFilteredAdvertisements(filtered);
//         } else {
//         setFilteredAdvertisements([]);
//         }
//     }, [adGroup, allAdvertisements]);

//     // Refetch data on component mount
//     useEffect(() => {
//         refetchLeadTypes();
//         refetchLeadSubTypes();
//         refetchCampaigns();
//         refetchAdvertisements();
//         refetchAdGroups();
//         refetchContactMethods();
//         refetchLocations();
//         refetchCountries();
//     }, []);

//     const handleCountryChange = (type, value) => {
//         const numericValue = Number(value); // Convert value to number
//         if (type === 'primary') {
//             setPrimaryPhone({ ...primaryPhone, country: numericValue });
//         } else {
//             setSecondaryPhone({ ...secondaryPhone, country: numericValue });
//         }
//     };

//     const handlePhoneNumberChange = (type, value) => {
//         if (type === 'primary') {
//             setPrimaryPhone({ ...primaryPhone, phoneNumber: value });
//         } else {
//             setSecondaryPhone({ ...secondaryPhone, phoneNumber: value });
//         }
//     };

//     useEffect(() => {
//         if (leadData) {
//             setName(leadData?.name || '');
//             setEmail(leadData?.email || '');
//             setLeadType(leadData?.leadType?._id || '');
//             setLeadSubType(leadData?.leadSubType?._id || '');
//             setPrimaryPhone(leadData?.primaryPhone || '');
//             setSecondaryPhone(leadData?.secondaryPhone || '');
//             setLandline(leadData?.landline || '');
//             setCampaign(leadData?.campaign?._id || '');
//             setAdvertisement(leadData?.advertisement?._id || '');
//             setAdGroup(leadData?.adGroup?._id || '');
//             setContactMethod(leadData?.contactMethod?._id || '');
//             setPreferredCallTime(leadData?.preferredCallTime || '');
//             setInterestedLocation(leadData?.interestedLocation?._id || '');
//             setBudgetDetails(leadData?.budgetDetails || {});
//             setBudgetValue(leadData?.budgetDetails?.value || '');
//             setBudgetCurrency(leadData?.budgetDetails?.currency || 'USD');
//             setAssignedSalesAgent(leadData?.assignedSalesAgent?._id || '');
//             setTrackingID(leadData?.trackingID || '');
//             setNotes(leadData?.notes || '');
//         }
//     }, [leadData]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsFormLoading(true);

//         // Convert phoneNumber values to numbers
//         const primaryPhoneNumber = Number(primaryPhone.phoneNumber);
//         const secondaryPhoneNumber = Number(secondaryPhone.phoneNumber);

//         try {
//             await updateLead({
//                 id,
//                 lead: {
//                     name, email, leadType, leadSubType,
//                     primaryPhone: { ...primaryPhone, phoneNumber: primaryPhoneNumber },
//                     secondaryPhone: { ...secondaryPhone, phoneNumber: secondaryPhoneNumber },
//                     landline, campaign, advertisement, adGroup, contactMethod,
//                     preferredCallTime, interestedLocation,
//                     budgetDetails: {
//                         value: budgetValue,
//                         currency: budgetCurrency,
//                     },
//                     trackingID, notes
//                 }
//             }).unwrap();
//             toast.success('Lead updated successfully');
//             navigate('/dashboard/lead');
//         } catch (err) {
//             toast.error('Failed to update Lead');
//         } finally {
//             setIsFormLoading(false);
//         }
//     };

//     return (
//         <div className="main-container container-fluid p-1 m-1">
//             <div className="row row-sm">
//                 <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 grid-margin">
//                     <div className="card">
//                         <div className="card-header pb-0">
//                             <div className="d-flex justify-content-between">
//                                 <h4 className="card-title mg-b-0">Add New Lead</h4>
//                             </div>
//                         </div>
//                         <div className="card-body">
//                             <ToastContainer />
//                             <form onSubmit={handleSubmit}>
//                                 <div className="row">
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 name='name'
//                                                 value={name}
//                                                 onChange={(e) => setName(e.target.value)}
//                                                 placeholder="Lead Name"
//                                             />
//                                             <label htmlFor="name">Lead Name</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="email"
//                                                 className="form-control"
//                                                 name='email'
//                                                 value={email}
//                                                 onChange={(e) => setEmail(e.target.value)}
//                                                 placeholder="Email"
//                                             />
//                                             <label htmlFor="email">Email</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='leadType'
//                                                 value={leadType}
//                                                 onChange={(e) => setLeadType(e.target.value)}
//                                             >
//                                                 <option value="">Select Lead Type</option>
//                                                 {leadTypes && leadTypes.map(type => (
//                                                     <option key={type._id} value={type._id}>{type.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="leadType">Lead Type</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='leadSubType'
//                                                 value={leadSubType}
//                                                 onChange={(e) => setLeadSubType(e.target.value)}
//                                             >
//                                                 <option value="">Select Lead SubType</option>
//                                                 {filteredLeadSubTypes && filteredLeadSubTypes.map(subType => (
//                                                 <option key={subType._id} value={subType._id}>{subType.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="leadSubType">Lead SubType</label>
//                                         </div>
//                                     </div>
//                                     {/* Primary Phone */}
//                                     <div className="col-md-6 mb-3">
//                                         <div className="input-group mb-3">
//                                             <div className="input-group-prepend">
//                                                 <select
//                                                     className="form-select"
//                                                     value={primaryPhone.country}
//                                                     onChange={(e) => handleCountryChange('primary', e.target.value)}
//                                                 >
//                                                     {countries && countries.map((country, index) => (
//                                                         <option key={country._id} value={country._id}>
//                                                             {country.symbol} ({country.code})
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                             <input
//                                                 type="number"
//                                                 className="form-control"
//                                                 placeholder="Primary Phone"
//                                                 value={primaryPhone.phoneNumber}
//                                                 onChange={(e) => handlePhoneNumberChange('primary', e.target.value)}
//                                             />
//                                         </div>
//                                     </div>
//                                     {/* Secondary Phone */}
//                                     <div className="col-md-6 mb-3">
//                                         <div className="input-group mb-3">
//                                             <div className="input-group-prepend">
//                                                 <select
//                                                     className="form-select"
//                                                     value={secondaryPhone.country}
//                                                     onChange={(e) => handleCountryChange('secondary', e.target.value)}
//                                                 >
//                                                     {countries && countries.map((country, index) => (
//                                                         <option key={country._id} value={country._id}>
//                                                             {country.symbol} ({country.code})
//                                                         </option>
//                                                     ))}
//                                                 </select>
//                                             </div>
//                                             <input
//                                                 type="number"
//                                                 className="form-control"
//                                                 placeholder="Secondary Phone"
//                                                 value={secondaryPhone.phoneNumber}
//                                                 onChange={(e) => handlePhoneNumberChange('secondary', e.target.value)}
//                                             />
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 name='landline'
//                                                 value={landline}
//                                                 onChange={(e) => setLandline(e.target.value)}
//                                                 placeholder="Landline"
//                                             />
//                                             <label htmlFor="landline">Landline</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='campaign'
//                                                 value={campaign}
//                                                 onChange={(e) => setCampaign(e.target.value)}
//                                             >
//                                                 <option value="">Select Campaign</option>
//                                                 {campaigns && campaigns.map(campaign => (
//                                                     <option key={campaign._id} value={campaign._id}>{campaign.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="campaign">Campaign</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='adGroup'
//                                                 value={adGroup}
//                                                 onChange={(e) => setAdGroup(e.target.value)}
//                                             >
//                                                 <option value="">Select Ad Group</option>
//                                                 {adGroups && adGroups.map(adGroup => (
//                                                     <option key={adGroup._id} value={adGroup._id}>{adGroup.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="adGroup">Ad Group</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='advertisement'
//                                                 value={advertisement}
//                                                 onChange={(e) => setAdvertisement(e.target.value)}
//                                             >
//                                                 <option value="">Select Advertisement</option>
//                                                 {filteredAdvertisements && filteredAdvertisements.map(advertisement => (
//                                                     <option key={advertisement._id} value={advertisement._id}>{advertisement.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="advertisement">Advertisement</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='contactMethod'
//                                                 value={contactMethod}
//                                                 onChange={(e) => setContactMethod(e.target.value)}
//                                             >
//                                                 <option value="">Select Contact Method</option>
//                                                 {contactMethods && contactMethods.map(method => (
//                                                     <option key={method._id} value={method._id}>{method.name}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="contactMethod">Contact Method</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 name='preferredCallTime'
//                                                 value={preferredCallTime}
//                                                 onChange={(e) => setPreferredCallTime(e.target.value)}
//                                                 placeholder="Preferred Call Time"
//                                             />
//                                             <label htmlFor="preferredCallTime">Preferred Call Time</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <select
//                                                 className="form-select"
//                                                 name='interestedLocation'
//                                                 value={interestedLocation}
//                                                 onChange={(e) => setInterestedLocation(e.target.value)}
//                                             >
//                                                 <option value="">Select Interested Location</option>
//                                                 {locations && locations.map(location => (
//                                                     <option key={location._id} value={location._id}>{location.name}, {location.city}, {location.country}</option>
//                                                 ))}
//                                             </select>
//                                             <label htmlFor="interestedLocation">Interested Location</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="row">
//                                             {/* Budget Details */}
//                                             <div className="col-md-6 mb-3">
//                                                 <div className="form-floating mb-2">
//                                                     <input
//                                                         type="number"
//                                                         className="form-control"
//                                                         name='budgetValue'
//                                                         min={0}
//                                                         value={budgetValue}
//                                                         onChange={(e) => setBudgetValue(e.target.value)}
//                                                         placeholder="Budget Value"
//                                                     />
//                                                     <label htmlFor="budgetValue">Budget Value</label>
//                                                 </div>
//                                             </div>
//                                             <div className="col-md-6 mb-3">
//                                                 <div className="form-floating mb-2">
//                                                     <select
//                                                         className="form-select"
//                                                         id="budgetCurrency"
//                                                         value={budgetCurrency}
//                                                         onChange={(e) => setBudgetCurrency(e.target.value)}
//                                                     >
//                                                         <option value="EGP">EGP</option>
//                                                         <option value="USD">USD</option>
//                                                         <option value="EUR">EUR</option>
//                                                         <option value="GBP">GBP</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 name='assignedSalesAgent'
//                                                 value={assignedSalesAgent}
//                                                 onChange={(e) => setAssignedSalesAgent(e.target.value)}
//                                                 placeholder="Assigned Sales Agent"
//                                             />
//                                             <label htmlFor="assignedSalesAgent">Assigned Sales Agent</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-6 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 name='trackingID'
//                                                 value={trackingID}
//                                                 onChange={(e) => setTrackingID(e.target.value)}
//                                                 placeholder="Tracking ID"
//                                             />
//                                             <label htmlFor="trackingID">Tracking ID</label>
//                                         </div>
//                                     </div>
//                                     <div className="col-md-12 mb-3">
//                                         <div className="form-floating mb-2">
//                                             <textarea
//                                                 className="form-control"
//                                                 name='notes'
//                                                 value={notes}
//                                                 onChange={(e) => setNotes(e.target.value)}
//                                                 placeholder="Notes"
//                                             />
//                                             <label htmlFor="notes">Notes</label>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <button className="btn btn-primary w-100" type="submit" disabled={isFormLoading}>
//                                     {isFormLoading ? 'Adding Lead...' : 'Add Lead'}
//                                 </button>

//                                 <div className="col-12 text-center mt-3">
//                                     <p className="mb-0 mt-3"><small className="text-dark me-2">Back to</small> <NavLink to='/lead' className="text-dark fw-bold">Lead List</NavLink></p>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditLead;

import React, { useEffect, useState, FormEvent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useUpdateLeadMutation,
  useGetLeadQuery,
} from "../../../features/lead/leadSlice";
import { toast, ToastContainer } from "react-toastify";
import { useGetLeadTypesQuery } from "../../../features/lead_type/leadTypeSlice";
import { useGetLeadSubTypesQuery } from "../../../features/lead_sub_type/leadSubTypeSlice";
import { useGetCampaignsQuery } from "../../../features/campaign/campaignSlice";
import { useGetAdvertisementsQuery } from "../../../features/advertisement/advertisementSlice";
import { useGetAdGroupsQuery } from "../../../features/ad_group/adGroupSlice";
import { useGetContactMethodsQuery } from "../../../features/contact_method/contactMethodSlice";
import { useGetLocationsQuery } from "../../../features/location/locationSlice";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Container,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import { LeadSubType } from "../../../interfaces/LeadSubType";
import { Advertisement } from "../../../interfaces/Advertisement";
import { PhoneNumber } from "../../../interfaces/PhoneNumber";

const EditLead: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [leadType, setLeadType] = useState<string>("");
  const [leadSubType, setLeadSubType] = useState<string>("");
  const [primaryPhone, setPrimaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [secondaryPhone, setSecondaryPhone] = useState<PhoneNumber>({
    country: "",
    phoneNumber: 0,
  });
  const [landline, setLandline] = useState<string>("");
  const [campaign, setCampaign] = useState<string>("");
  const [advertisement, setAdvertisement] = useState<string>("");
  const [adGroup, setAdGroup] = useState<string>("");
  const [contactMethod, setContactMethod] = useState<string>("");
  const [preferredCallTime, setPreferredCallTime] = useState<string>("");
  const [interestedLocation, setInterestedLocation] = useState<string>("");
  const [budgetDetails, setBudgetDetails] = useState<{
    value: string;
    currency: string;
  }>({ value: "", currency: "USD" });
  // const [assignedSalesAgent, setAssignedSalesAgent] = useState<string>("");
  const [trackingID, setTrackingID] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

  const { data: leadTypes, refetch: refetchLeadTypes } = useGetLeadTypesQuery({
    page: 1,
    limit: 0,
  });
  const { data: allLeadSubTypes, refetch: refetchLeadSubTypes } =
    useGetLeadSubTypesQuery({
      page: 1,
      limit: 0,
    });
  const { data: campaigns, refetch: refetchCampaigns } = useGetCampaignsQuery({
    page: 1,
    limit: 0,
  });
  const { data: allAdvertisements, refetch: refetchAdvertisements } =
    useGetAdvertisementsQuery({
      page: 1,
      limit: 0,
    });
  const { data: adGroups, refetch: refetchAdGroups } = useGetAdGroupsQuery({
    page: 1,
    limit: 0,
  });
  const { data: contactMethods, refetch: refetchContactMethods } =
    useGetContactMethodsQuery({
      page: 1,
      limit: 0,
    });
  const { data: locations, refetch: refetchLocations } = useGetLocationsQuery({
    page: 1,
    limit: 0,
  });
  const { data: countries, refetch: refetchCountries } = useGetCountriesQuery();

  const [filteredLeadSubTypes, setFilteredLeadSubTypes] = useState<
    LeadSubType[]
  >([]);

  const [filteredAdvertisements, setFilteredAdvertisements] = useState<
    Advertisement[]
  >([]);

  const { data: leadData, refetch } = useGetLeadQuery(id!);
  const [updateLead] = useUpdateLeadMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (leadType && allLeadSubTypes) {
      const filtered = allLeadSubTypes.data.filter((subType) => {
        // Check if subType.leadType is an object or a string
        if (typeof subType.leadType === "object" && subType.leadType !== null) {
          return subType.leadType._id === leadType;
        } else {
          return subType.leadType === leadType;
        }
      });
      setFilteredLeadSubTypes(filtered);
    } else {
      setFilteredLeadSubTypes([]);
    }
  }, [leadType, allLeadSubTypes]);

  useEffect(() => {
    if (adGroup && allAdvertisements) {
      const filtered = allAdvertisements.data.filter((ad) => {
        // Check if subType.leadType is an object or a string
        if (typeof ad.adGroup === "object" && ad.adGroup !== null) {
          return ad.adGroup._id === adGroup;
        } else {
          return ad.adGroup === adGroup;
        }
      });
      setFilteredAdvertisements(filtered);
    } else {
      setFilteredAdvertisements([]);
    }
  }, [adGroup, allAdvertisements]);

  useEffect(() => {
    refetchLeadTypes();
    refetchLeadSubTypes();
    refetchCampaigns();
    refetchAdvertisements();
    refetchAdGroups();
    refetchContactMethods();
    refetchLocations();
    refetchCountries();
  }, []);

  useEffect(() => {
    if (leadData) {
      setName(leadData.name || "");
      setEmail(leadData.email || "");
      setLeadType(
        typeof leadData.leadType === "object"
          ? leadData.leadType._id || ""
          : leadData.leadType || ""
      );
      setLeadSubType(
        typeof leadData.leadSubType === "object"
          ? leadData.leadSubType._id || ""
          : leadData.leadSubType || ""
      );
      // Ensure you check if country is an object before accessing _id
      const primaryCountryId =
        typeof leadData.primaryPhone.country === "object"
          ? leadData.primaryPhone.country._id || ""
          : leadData.primaryPhone.country || ""; // Handle the case when country is a string

      setPrimaryPhone({
        country: primaryCountryId,
        phoneNumber: leadData.primaryPhone.phoneNumber || 0,
      });

      const secondaryCountryId =
        typeof leadData.secondaryPhone.country === "object"
          ? leadData.secondaryPhone.country._id || ""
          : leadData.secondaryPhone.country || ""; // Handle the case when country is a string

      setSecondaryPhone({
        country: secondaryCountryId,
        phoneNumber: leadData.secondaryPhone.phoneNumber || 0,
      });
      setLandline(leadData.landline || "");
      setCampaign(
        typeof leadData.campaign === "object"
          ? leadData.campaign._id || ""
          : leadData.campaign || ""
      );
      setAdvertisement(
        typeof leadData.advertisement === "object"
          ? leadData.advertisement._id || ""
          : leadData.advertisement || ""
      );
      setAdGroup(
        typeof leadData.adGroup === "object"
          ? leadData.adGroup._id || ""
          : leadData.adGroup || ""
      );
      setContactMethod(
        typeof leadData.contactMethod === "object"
          ? leadData.contactMethod._id || ""
          : leadData.contactMethod || ""
      );
      setPreferredCallTime(leadData.preferredCallTime || "");
      setInterestedLocation(
        typeof leadData.interestedLocation === "object"
          ? leadData.interestedLocation._id || ""
          : leadData.interestedLocation || ""
      );
      setBudgetDetails(
        leadData.budgetDetails || { value: "", currency: "USD" }
      );
      setTrackingID(leadData.trackingID || "");
      setNotes(leadData.notes || "");
    }
  }, [leadData]);

  const handleCountryChange = (
    phoneType: "primary" | "secondary",
    value: string
  ) => {
    // Find the selected country by its ID
    const selectedCountry = countries?.find((country) => country._id === value);

    // // Set the country to the _id of the selected country, or handle it accordingly
    // const countryValue = selectedCountry ? selectedCountry._id : ""; // Use an empty string or another fallback

    const countryId =
      typeof selectedCountry === "object"
        ? selectedCountry._id || ""
        : selectedCountry || "";

    if (phoneType === "primary") {
      setPrimaryPhone({
        ...primaryPhone,
        country: countryId, // Assign the country _id
      });
    } else {
      setSecondaryPhone({
        ...secondaryPhone,
        country: countryId, // Assign the country _id
      });
    }
  };

  const handlePhoneNumberChange = (
    phoneType: "primary" | "secondary",
    value: string
  ) => {
    const phoneNumber = parseInt(value, 10) || 0; // Convert to number, fallback to 0
    if (phoneType === "primary") {
      setPrimaryPhone({ ...primaryPhone, phoneNumber });
    } else {
      setSecondaryPhone({ ...secondaryPhone, phoneNumber });
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await updateLead({
        id: id!,
        lead: {
          name,
          email,
          leadType,
          leadSubType,
          primaryPhone,
          secondaryPhone,
          landline,
          campaign,
          advertisement,
          adGroup,
          contactMethod,
          preferredCallTime,
          interestedLocation,
          budgetDetails,
          trackingID,
          notes,
        },
      }).unwrap();
      toast.success("Lead updated successfully");
      navigate("/dashboard/lead");
    } catch (err) {
      toast.error("Failed to update Lead");
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Lead Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Lead Type</InputLabel>
              <Select
                value={leadType}
                onChange={(e) => setLeadType(e.target.value as string)}
                required
              >
                <MenuItem value="">
                  <em>Select Lead Type</em>
                </MenuItem>
                {leadTypes &&
                  leadTypes.data.map((type) => (
                    <MenuItem key={type._id} value={type._id}>
                      {type.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Lead SubType</InputLabel>
              <Select
                value={leadSubType}
                onChange={(e) => setLeadSubType(e.target.value as string)}
              >
                <MenuItem value="">
                  <em>Select Lead SubType</em>
                </MenuItem>
                {filteredLeadSubTypes &&
                  filteredLeadSubTypes.map((subType) => (
                    <MenuItem key={subType._id} value={subType._id}>
                      {subType.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Ad Group</InputLabel>
              <Select
                value={adGroup}
                onChange={(e) => setAdGroup(e.target.value as string)}
              >
                <MenuItem value="">
                  <em>Select Ad Group</em>
                </MenuItem>
                {adGroups &&
                  adGroups.data.map((group) => (
                    <MenuItem key={group._id} value={group._id}>
                      {group.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Advertisement</InputLabel>
              <Select
                value={advertisement}
                onChange={(e) => setAdvertisement(e.target.value as string)}
              >
                <MenuItem value="">
                  <em>Select Advertisement</em>
                </MenuItem>
                {filteredAdvertisements &&
                  filteredAdvertisements.map((ad) => (
                    <MenuItem key={ad._id} value={ad._id}>
                      {ad.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Campaign</InputLabel>
              <Select
                value={campaign}
                onChange={(e) => setCampaign(e.target.value as string)}
                required
              >
                <MenuItem value="">
                  <em>Select Campaign</em>
                </MenuItem>
                {campaigns &&
                  campaigns.data.map((camp) => (
                    <MenuItem key={camp._id} value={camp._id}>
                      {camp.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Contact Method</InputLabel>
              <Select
                value={contactMethod}
                onChange={(e) => setContactMethod(e.target.value as string)}
                required
              >
                <MenuItem value="">
                  <em>Select Contact Method</em>
                </MenuItem>
                {contactMethods &&
                  contactMethods.data.map((method) => (
                    <MenuItem key={method._id} value={method._id}>
                      {method.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Interested Location</InputLabel>
              <Select
                value={interestedLocation}
                onChange={(e) =>
                  setInterestedLocation(e.target.value as string)
                }
              >
                <MenuItem value="">
                  <em>Select Interested Location</em>
                </MenuItem>
                {locations &&
                  locations.data.map((loc) => (
                    <MenuItem key={loc._id} value={loc._id}>
                      {loc.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Tracking ID"
              value={trackingID}
              onChange={(e) => setTrackingID(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Preferred Call Time"
              value={preferredCallTime}
              onChange={(e) => setPreferredCallTime(e.target.value)}
              type="time"
            />
          </Grid>
          {/* Primary Phone */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    value={
                      typeof primaryPhone.country === "string"
                        ? primaryPhone.country
                        : primaryPhone.country._id
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      handleCountryChange("primary", e.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Country" }}
                    size="small"
                  >
                    {countries?.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.symbol}({country.code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  type="tel"
                  label="Primary Phone"
                  value={primaryPhone.phoneNumber.toString()} // Convert to string for TextField
                  onChange={(e) =>
                    handlePhoneNumberChange("primary", e.target.value)
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Secondary Phone */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Select
                    value={
                      typeof secondaryPhone.country === "string"
                        ? secondaryPhone.country
                        : secondaryPhone.country._id
                    }
                    onChange={(e: SelectChangeEvent<string>) =>
                      handleCountryChange("secondary", e.target.value)
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Country" }}
                    size="small"
                  >
                    {countries?.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.symbol}({country.code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  type="tel"
                  label="Secondary Phone"
                  value={secondaryPhone.phoneNumber.toString()} // Convert to string for TextField
                  onChange={(e) =>
                    handlePhoneNumberChange("secondary", e.target.value)
                  }
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Landline"
              value={landline}
              onChange={(e) => setLandline(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Budget Currency</InputLabel>
              <Select
                value={budgetDetails.currency}
                onChange={(e) =>
                  setBudgetDetails({
                    ...budgetDetails,
                    currency: e.target.value as string,
                  })
                }
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
                {/* Add more currency options here */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Budget Value"
              value={budgetDetails.value}
              onChange={(e) =>
                setBudgetDetails({ ...budgetDetails, value: e.target.value })
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isFormLoading}
              startIcon={
                isFormLoading ? <CircularProgress size="1rem" /> : undefined
              }
            >
              Update Lead
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default EditLead;
