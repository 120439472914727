import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import {
  Announcement,
  Campaign,
  Chat,
  Folder,
  Layers,
  Sync,
  Map,
  Work,
  Group,
} from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";

export const MainListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => navigate("/dashboard")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      {/* <ListItemButton>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton> */}
    </React.Fragment>
  );
};

export const SecondaryListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        MASTER DATA
      </ListSubheader>
      <ListItemButton onClick={() => navigate("/dashboard/lead-status")}>
        <ListItemIcon>
          <Sync />
        </ListItemIcon>
        <ListItemText primary="Lead Status" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/lead-type")}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Lead Type" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/lead-sub-type")}>
        <ListItemIcon>
          <Layers />
        </ListItemIcon>
        <ListItemText primary="Lead SubType" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/ad-group")}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText primary="Ad Group" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/advertisement")}>
        <ListItemIcon>
          <Announcement />
        </ListItemIcon>
        <ListItemText primary="Advertisement" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/campaign")}>
        <ListItemIcon>
          <Campaign />
        </ListItemIcon>
        <ListItemText primary="Campaign" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/contact-method")}>
        <ListItemIcon>
          <Chat />
        </ListItemIcon>
        <ListItemText primary="Contact Method" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/location")}>
        <ListItemIcon>
          <Map />
        </ListItemIcon>
        <ListItemText primary="Location" />
      </ListItemButton>
      <ListItemButton onClick={() => navigate("/dashboard/user")}>
        <ListItemIcon>
          <Group />
        </ListItemIcon>
        <ListItemText primary="User" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const ThirdListItems = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        LEADS
      </ListSubheader>
      <ListItemButton onClick={() => navigate("/dashboard/lead")}>
        <ListItemIcon>
          <Work />
        </ListItemIcon>
        <ListItemText primary="Leads" />
      </ListItemButton>
    </React.Fragment>
  );
};
