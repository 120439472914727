import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import { Add, Delete, Edit, Visibility } from "@mui/icons-material";
import {
  useDeleteLeadMutation,
  useGetLeadsQuery,
} from "../../../features/lead/leadSlice";
import { Lead } from "../../../interfaces/Lead";
import ConfirmationDialog from "../common/ConfirmationDialog";
import { lightBlue } from "@mui/material/colors";
import { useGetCampaignsQuery } from "../../../features/campaign/campaignSlice";
import { useGetAdvertisementsQuery } from "../../../features/advertisement/advertisementSlice";
import { useGetAdGroupsQuery } from "../../../features/ad_group/adGroupSlice";
import { useGetLocationsQuery } from "../../../features/location/locationSlice";
import { useSelector } from "react-redux";
import { useGetSubordinatesQuery } from "../../../features/user/userSlice";
import { selectCurrentUser } from "../../../features/auth/authSlice";
import { useGetCountriesQuery } from "../../../features/country/countrySlice";

const LeadList: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [filterQuery, setFilterQuery] = useState<any>({});
  const limit = 10;

  const userData = useSelector(selectCurrentUser); // Fetch current user
  const { data: subordinates } = useGetSubordinatesQuery(); // Fetch subordinates

  const { data: countries } = useGetCountriesQuery();

  // const { data: leadTypes } = useGetLeadTypesQuery({ page: 1, limit: 0 });
  const { data: campaigns } = useGetCampaignsQuery({ page: 1, limit: 0 });
  const { data: advertisements } = useGetAdvertisementsQuery({
    page: 1,
    limit: 0,
  });
  const { data: adGroups } = useGetAdGroupsQuery({ page: 1, limit: 0 });
  const { data: locations } = useGetLocationsQuery({ page: 1, limit: 0 });

  const { data, error, isLoading, refetch } = useGetLeadsQuery({
    page,
    limit,
    filterQuery:
      Object.keys(filterQuery).length === 0 ? undefined : filterQuery,
  });

  useEffect(() => {
    refetch();
  }, []);

  const [deleteLead] = useDeleteLeadMutation();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);

  const addLead = () => {
    navigate("/dashboard/add-lead");
  };

  const handleDelete = async () => {
    if (selectedLeadId) {
      await deleteLead(selectedLeadId);
      setShowDialog(false);
      refetch();
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      setPage(newPage);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterQuery((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFilterQuery((prev: any) => ({
      ...prev,
      [name as string]: value,
    }));
  };

  const handleAssignedAgentChange = (e: SelectChangeEvent<string[]>) => {
    setFilterQuery((prev: any) => ({
      ...prev,
      assignedSalesAgent: e.target.value, // ensure it's passed as an array of IDs
    }));
  };

  // Handle primaryPhone changes
  const handlePrimaryPhoneChange = (
    field: "country" | "phoneNumber",
    value: any
  ) => {
    setFilterQuery((prev: any) => ({
      ...prev,
      [`primaryPhone[${field}]`]:
        field === "phoneNumber" ? Number(value) : value, // Format to `primaryPhone[country]` or `primaryPhone[phoneNumber]`
    }));
  };

  // Handle secondaryPhone changes
  const handleSecondaryPhoneChange = (
    field: "country" | "phoneNumber",
    value: any
  ) => {
    setFilterQuery((prev: any) => ({
      ...prev,
      [`secondaryPhone[${field}]`]:
        field === "phoneNumber" ? Number(value) : value, // Format to `secondaryPhone[country]` or `secondaryPhone[phoneNumber]`
    }));
  };

  const handleFilterClick = () => {
    refetch();
  };

  const handleClearFilter = () => {
    setFilterQuery({});
    refetch(); // This will trigger fetching leads without any filters
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading leads</Typography>;

  const totalPages = Math.ceil((data?.total || 0) / limit);

  return (
    <Box className="main-container" p={2}>
      <Typography variant="h4" gutterBottom>
        Lead Table
      </Typography>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        sx={{ mb: 2 }}
        onClick={addLead}
      >
        Add New Lead
      </Button>
      <Box mb={2} display="flex" flexWrap="wrap" gap={2}>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          onChange={handleInputChange}
        />
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  value={filterQuery?.["primaryPhone[country]"] || ""} // Access the flattened structure
                  onChange={(e) =>
                    handlePrimaryPhoneChange("country", e.target.value)
                  }
                >
                  {countries?.map((country: any) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.symbol} ({country.code})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="number"
                label="Primary Phone"
                value={filterQuery?.["primaryPhone[phoneNumber]"] || ""} // Access the flattened structure
                onChange={(e) =>
                  handlePrimaryPhoneChange("phoneNumber", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  value={filterQuery?.["secondaryPhone[country]"] || ""} // Access the flattened structure
                  onChange={(e) =>
                    handleSecondaryPhoneChange("country", e.target.value)
                  }
                >
                  {countries?.map((country: any) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.symbol} ({country.code})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="number"
                label="Secondary Phone"
                value={filterQuery?.["secondaryPhone[phoneNumber]"] || ""} // Access the flattened structure
                onChange={(e) =>
                  handleSecondaryPhoneChange("phoneNumber", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <TextField
          label="Tracking ID"
          variant="outlined"
          name="trackingID"
          onChange={handleInputChange}
        />

        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Campaign</InputLabel>
          <Select
            name="campaign"
            value={filterQuery.campaign || ""}
            onChange={handleSelectChange}
            label="Campaign"
          >
            {campaigns?.data.map((campaign: any) => (
              <MenuItem key={campaign._id} value={campaign._id}>
                {campaign.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Advertisement</InputLabel>
          <Select
            name="advertisement"
            value={filterQuery.advertisement || ""}
            onChange={handleSelectChange}
            label="Advertisement"
          >
            {advertisements?.data.map((ad: any) => (
              <MenuItem key={ad._id} value={ad._id}>
                {ad.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Ad Group</InputLabel>
          <Select
            name="adGroup"
            value={filterQuery.adGroup || ""}
            onChange={handleSelectChange}
            label="Ad Group"
          >
            {adGroups?.data.map((group: any) => (
              <MenuItem key={group._id} value={group._id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Interested Location</InputLabel>
          <Select
            name="interestedLocation"
            value={filterQuery.interestedLocation || ""}
            onChange={handleSelectChange}
            label="Interested Location"
          >
            {locations?.data.map((location: any) => (
              <MenuItem key={location._id} value={location._id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Assigned Sales Agent</InputLabel>
          <Select
            name="assignedSalesAgent"
            multiple
            value={
              Array.isArray(filterQuery.assignedSalesAgent)
                ? filterQuery.assignedSalesAgent
                : []
            } // Ensure it's always an array
            onChange={handleAssignedAgentChange}
            label="Assigned Sales Agent"
            renderValue={(selected) => selected.join(", ")}
          >
            {/* Render current user + subordinates */}
            <MenuItem value={userData._id}>
              <Checkbox
                checked={
                  Array.isArray(filterQuery.assignedSalesAgent) &&
                  filterQuery.assignedSalesAgent.includes(userData._id)
                } // Check with array validation
              />
              <ListItemText primary={userData.name} />
            </MenuItem>
            {subordinates?.map((agent: any) => (
              <MenuItem key={agent._id} value={agent._id}>
                <Checkbox
                  checked={
                    Array.isArray(filterQuery.assignedSalesAgent) &&
                    filterQuery.assignedSalesAgent.includes(agent._id)
                  } // Check with array validation
                />
                <ListItemText primary={agent.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleFilterClick}>
          Filter
        </Button>
        <Button
          variant="contained"
          // variant="outlined"
          color="secondary"
          onClick={handleClearFilter}
        >
          Clear Filter
        </Button>
      </Box>

      {/* Lead Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lead Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Lead Type</TableCell>
              <TableCell>Lead SubType</TableCell>
              <TableCell>Primary Phone</TableCell>
              <TableCell>Assigned Sales Agent</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((lead: Lead) => (
              <TableRow key={lead._id}>
                <TableCell>{lead.name}</TableCell>
                <TableCell>{lead.email}</TableCell>
                <TableCell>
                  {lead.leadType && typeof lead.leadType !== "string"
                    ? lead.leadType.name
                    : "Unknown Lead Type"}
                </TableCell>
                <TableCell>
                  {lead.leadSubType && typeof lead.leadSubType !== "string"
                    ? lead.leadSubType.name
                    : "Unknown Lead Sub Type"}
                </TableCell>
                <TableCell>
                  {lead.primaryPhone.country &&
                  typeof lead.primaryPhone.country !== "string"
                    ? `${lead.primaryPhone.country.code} ${lead.primaryPhone.phoneNumber}`
                    : "Unknown Country"}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor:
                      lead.assignedSalesAgent &&
                      typeof lead.assignedSalesAgent !== "string"
                        ? `${lead.assignedSalesAgent.color}` // Replace with the hex color you want ff5733
                        : "#ffcccb", // Optionally, a different color for unknown agent ffcccb
                  }}
                >
                  {lead.assignedSalesAgent &&
                  typeof lead.assignedSalesAgent !== "string"
                    ? `${lead.assignedSalesAgent.name}`
                    : "Unknown Assigned Sales Agent"}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor:
                      lead.status && typeof lead.status !== "string"
                        ? `${lead.status.color}` // Replace with the hex color you want ff5733
                        : "#ffcccb", // Optionally, a different color for unknown agent ffcccb
                  }}
                >
                  {lead.status && typeof lead.status !== "string"
                    ? `${lead.status.status}`
                    : "Unknown Status"}
                </TableCell>
                <TableCell>
                  <IconButton
                    component={Link}
                    to={`/dashboard/view-lead/${lead._id}`}
                  >
                    <Visibility sx={{ color: lightBlue[500] }} />
                  </IconButton>
                  <IconButton
                    component={Link}
                    to={`/dashboard/edit-lead/${lead._id}`}
                  >
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedLeadId(lead._id ?? null);
                      setShowDialog(true);
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>

      {/* Confirmation Dialog */}
      {showDialog && (
        <ConfirmationDialog
          show={showDialog}
          title="Confirm Deletion"
          message="Are you sure you want to delete this Advertisement?"
          onConfirm={handleDelete}
          onCancel={() => setShowDialog(false)}
        />
      )}
    </Box>
  );
};

export default LeadList;
